export default [
  {
    label: 'Address',
    field: 'address',
  },
  {
    label: 'Balance',
    field: 'balance',
  },
  {
    label: 'Asset',
    field: 'asset',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
